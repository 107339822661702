import { Grid, Paper } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import Logo from '../../assets/Logo.svg'
import DownloadIcon from '../../assets/DownloadIcon.svg'

export default function Header() {
    return (
        <Paper className="fixed-navbar mh-90 h-100 mb-10" elevation={10}>
            <Grid container className='ai-center pr-75 pl-75 h-100'>
                <Grid item xs={3} >
                    <Box component={'img'}
                        src={Logo}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Grid container className='jc-center'>
                        <Link to="/" className='mr-45 fs-16 fw-500 lh-24'>Home</Link>
                        <Link to="/about" className='mr-45 fs-16 fw-500 lh-24'>About Us</Link>
                        <Link to="/services" className='mr-45 fs-16 fw-500 lh-24'>Services</Link>
                        <Link to="/blogs" className='mr-45 fs-16 fw-500 lh-24'>Blogs</Link>
                        <Link to="/contact" className='fs-16 fw-500 lh-24'>Contact Us</Link>
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Grid container className='jc-center ai-center'>
                        <Link to='#' className='mr-2'>
                            <Box component={'img'}
                                src={DownloadIcon}
                            />
                        </Link>
                        <Link to="#" className='mr-45 fs-16 fw-500 lh-24'>
                            <span>Downlod</span>
                        </Link>
                        <Link to="/login" className='fs-16 fw-500 lh-24'>Login</Link>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}
