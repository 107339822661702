import { Grid, Typography } from '@mui/material'
import { Box, color, height } from '@mui/system'
import React from 'react'
import Rectangle from '../../../assets/Rectangle.svg'
import Wave from '../../../assets/Wave.svg'
import Icon1 from '../../../assets/Icon1.svg'
import Icon2 from '../../../assets/Icon2.svg'
import Icon3 from '../../../assets/Icon3.svg'
import Icon4 from '../../../assets/Icon4.svg'
import Icon5 from '../../../assets/Icon5.svg'
import Icon6 from '../../../assets/Icon6.svg'
import Icon7 from '../../../assets/Icon7.svg'
import Icon8 from '../../../assets/Icon8.svg'
import Wave2 from '../../../assets/Wave2.svg'
import Logo0 from '../../../assets/Logo0.svg'
import Wave3 from '../../../assets/Wave3.svg'
import Icon9 from '../../../assets/Icon9.svg'
import Icon10 from '../../../assets/Icon10.svg'
import Icon11 from '../../../assets/Icon11.svg'
import Logo from '../../../assets/Logo.svg'
import Rectangle2 from '../../../assets/Rectangle2.svg'
import Rectangle3 from '../../../assets/Rectangle3.svg'
import './about.scss'
import Rectangle1 from '../../../assets/Rectangle1.svg'
import FooterImg from '../../../assets/FooterImg.svg'
import ProfileImg from '../../../assets/ProfileImg.svg'
import Wave4 from '../../../assets/Wave4.svg'
import Instagram1 from '../../../assets/Instagram1.png'
import Instagram2 from '../../../assets/Instagram2.png'
import Instagram3 from '../../../assets/Instagram3.png'
import Instagram4 from '../../../assets/Instagram4.png'

export default function About() {
    return (
        <> <div>

            <Grid container className=' app-container jc-center wave-image1' sx={{ minHeight: { xs: 300, sm: 700, lg: 900 }, marginTop: '100px' }}>
                <Grid item xs={12}>
                    <Grid item className='text-center'>
                        <p className='fs-24 fw-700 lh-36 m-0'>About Us</p>
                    </Grid>
                    <Grid item className='text-center'>

                        <Box component={'img'}
                            sx={{

                                maxHeight: { xs: 80, md: 140 }
                            }}

                            src={Rectangle}
                        />

                    </Grid>
                    <Grid item>
                        <Grid container justifyContent={'center'}>
                            <Typography className='fw-500 text-center fs-24 lh-30' sx={{ maxWidth: { xs: '450px', sm: '500px', md: '550px' } }}>
                                At Go Passive, one of the exclusive mediums to
                                generate passive income, we believe trading requires
                                expertise, thorough knowledge of markets, and a
                                balanced approach to play safe and bright in this
                                fastest-growing trading world.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>



                <Grid item className='pt-99'>
                    <Grid container sx={{ width: '60px', height: '60px', borderRadius: '50%' }} className='bg-1 jc-center'>
                        <Box component={'img'}
                            src={Icon1}

                        />
                    </Grid>
                    <Grid container>
                        <Grid item>
                            <p className='fw-700'>The Best Algo-trading System</p>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={8} sm={7}>
                            <p className='text-muted m-0 fw-500 lh-25'>Go Passive is an algo trading system, where we look to serve people with an automated trading system that does not require any manual interventions and is fully examined based on past performances to generate fair returns throughout their lives.</p>
                        </Grid>
                    </Grid>
                </Grid>


            </Grid>




            <Grid container justifyContent={'flex-end'} className='app-container' sx={{ paddingRight: '100px' }}>
                <Grid item xs={8} sm={7}>
                    <Grid container sx={{ width: '60px', height: '60px', borderRadius: '50%' }} className='bg-1 jc-center'>
                        <Box component={'img'}
                            src={Icon2}

                        />
                    </Grid>
                    <Grid container>
                        <Grid item>
                            <p className='fw-700'>A Fiduciary Mindset</p>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item>
                            <Typography className='text-muted m-0 fw-500 lh-25'>the base of our identity. We work collaboratively for the success of all of our clients, our firm, and the communities where we operate sustainably and responsibly.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>



            <Grid container className='app-container wave-image2' sx={{ minHeight: { xs: 600, sm: 650 } }}>
                <Grid item xs={8} sm={5.4} className='p'>
                    <Grid container sx={{ width: '60px', height: '60px', borderRadius: '50%' }} className='bg-1 jc-center'>
                        <Box component={'img'}
                            src={Icon3}

                        />
                    </Grid>
                    <Grid container>
                        <Grid item>
                            <p className='fw-700'>Long-Term Vision</p>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item>
                            <Typography className='text-muted m-0 fw-500 lh-25'>
                                the base of our identity. We work collaboratively for the success of all of our clients, our firm, and the communities where we operate sustainably and responsibly.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid cotainer className='jc-center pl-50' sx={{ paddingTop: { lg: '200px' } }}>
                        <Grid item>
                            <p className='fs-24 fw-700 lh-36 m-0 text-center '>Our Values</p>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container className='jc-center'>
                                <Box component={'img'}

                                    src={Rectangle1}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>



            <Grid container className='jc-center mt-80'>
                {/* <Grid item>
                    <p className='fs-24 fw-700 lh-36 m-0'>Our Values</p>
                </Grid>
                <Grid item xs={12}>
                    <Grid container className='jc-center'>
                        <Box component={'img'}

                            src={Rectangle1}
                        />
                    </Grid>
                </Grid> */}


                <Grid container className='app-container'>
                    <Grid item xs={7}>
                        <Grid container sx={{ width: '60px', height: '60px', borderRadius: '50%' }} className='bg-2 jc-center ai-center'>
                            <Box component={'img'}
                                src={Logo0}
                                sx={{


                                    height: 70,


                                }}
                            />
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <p className='fw-700'>We are GoPassive</p>
                            </Grid>
                        </Grid>
                        <Grid container >
                            <Grid item >
                                <p className='text-muted m-0 fw-500 lh-25'>We believe trading requires expertise, thorough knowledge of markets, and a balanced approach to play safe and bright in this fastest-growing trading world. We are a passive income-generating source. Here we look forward to serving the best outcomes for our clients, and our firm.</p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container justifyContent={'flex-end'} className='app-container mt-50'>
                    <Grid item xs={7}>
                        <Grid container sx={{ width: '60px', height: '60px', borderRadius: '50%' }} className='bg-2 jc-center ai-center'>
                            <Box component={'img'}
                                src={Icon4}

                            />
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <p className='fw-700'>Our Goal</p>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <Typography className='text-muted m-0 fw-500 lh-25'>The client’s interest is the priority. Keeping up a fiduciary mindset reflects our commitment to serving people with the best of our services. We provide unbiased advice and help our clients nurture.
                                    Generating good returns for people is what inspires us to work and help them meet their goals.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>



                <Grid container className='app-container'>
                    <Grid item xs={7}>
                        <Grid container sx={{ width: '60px', height: '60px', borderRadius: '50%' }} className='bg-2 jc-center ai-center'>
                            <Box component={'img'}
                                src={Icon5}

                            />
                        </Grid>
                        <Grid container>

                            <Grid item>
                                <p className='fw-700'>Performance Driven</p>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <Typography className='text-muted m-0 fw-500 lh-25'>We do thorough research before punching orders to achieve the best outcomes. We believe in delivering quality results and staying ahead of our client’s needs. We implement a robust system with the least possible chances of error.

                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container justifyContent={'flex-end'} className='app-container mt-50'>
                    <Grid item xs={7}>
                        <Grid container sx={{ width: '60px', height: '60px', borderRadius: '50%' }} className='bg-2 jc-center ai-center'>
                            <Box component={'img'}
                                src={Icon6}

                            />
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <p className='fw-700'>Transparency</p>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <Typography className='text-muted m-0 fw-500 lh-25'>We disclose end-to-end reports showing profits, returns, risks, and more. With Go passive, you get to observe how your trading strategies are performing over time, and we are fully transparent about all the actions made.

                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>


                <Grid container className='app-container'>
                    <Grid item xs={7}>
                        <Grid container sx={{ width: '60px', height: '60px', borderRadius: '50%' }} className='bg-2 jc-center ai-center'>
                            <Box component={'img'}
                                src={Icon7}

                            />
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <p className='fw-700'>Accessibility</p>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <Typography className='text-muted m-0 fw-500 lh-25'>We work to ensure that the investors have full access to all the strategies offered according to their risk appetite to help them meet their financial goals. We are a dedicated team of professionals to look after the needs of investors and give them the best chance for profitable returns.

                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>


                <Grid container justifyContent={'flex-end'} className='app-container mt-50'>
                    <Grid item xs={7}>
                        <Grid container sx={{ width: '60px', height: '60px', borderRadius: '50%' }} className='bg-2 jc-center ai-center'>
                            <Box component={'img'}
                                src={Icon8}

                            />
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <p className='fw-700'>Committed to a Better Future</p>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <Typography className='text-muted m-0 fw-500 lh-25'>We believe in a long-term approach and so we are focused on helping people build a better tomorrow. We do care about the success and downfalls of all of our stakeholders – our clients, our employees, and the communities where we operate, to drive them long-term value that helps them achieve financial ease.

                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>




            <Grid container className='jc-center mt-80 wave-image3' sx={{ minHeight: { sm: 950 }, alignItems: 'end' }}>
                <Grid item xs={12}>
                    <Grid item className='text-center'>
                        <p className='fs-24 fw-700 lh-36 text-center m-0'>Vision</p>
                        <Box component={'img'}
                            src={Rectangle2}
                            sx={{ maxHeight: '250px' }}
                        />

                    </Grid>
                    <Grid item>
                        <Grid container justifyContent={'center'}>
                            <Typography className='mw-450 fw-500  text-center fs-16 lh-24 text-muted'>
                                Our vision is to bridge the gap between people's
                                willingness to invest (due to a lack of self-awareness,
                                research capabilities, and time) and their ability to
                                earn a hassle-free income from their investments.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>



            <Grid container className='app-container mt-50'>
                <Grid item xs={7}>
                    <Grid container sx={{ width: '60px', height: '60px', borderRadius: '50%' }} className='bg-3 jc-center ai-center'>
                        <Box component={'img'}
                            src={Icon9}

                        />
                    </Grid>
                    <Grid container>
                        <Grid item>
                            <p className='fw-700'>Reliable Results</p>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item>
                            <p className='text-muted m-0 fw-500 lh-25'>During the term, our key values provide discipline, knowledge, and transparency in all aspects, resulting in such returns that clients can fully rely on us.</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>



            <Grid container justifyContent={'flex-end'} className='app-container mt-50'>
                <Grid item xs={7}>
                    <Grid container sx={{ width: '60px', height: '60px', borderRadius: '50%' }} className='bg-3 jc-center ai-center'>
                        <Box component={'img'}
                            src={Icon10}

                        />
                    </Grid>
                    <Grid container>
                        <Grid item>
                            <p className='fw-700'>Persistent Effort</p>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item>
                            <Typography className='text-muted m-0 fw-500 lh-25'>We make every effort to maintain the confidence we've earned from our investors while also helping more individuals with our unbiased advice and knowledge. We keep a close eye on how well we're servicing our clients' needs.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>


            <Grid container className='app-container'>
                <Grid item xs={7}>
                    <Grid container sx={{ width: '60px', height: '60px', borderRadius: '50%' }} className='bg-3 jc-center ai-center'>
                        <Box component={'img'}
                            src={Icon11}

                        />
                    </Grid>
                    <Grid container>
                        <Grid item>
                            <p className='fw-700'>Robust System</p>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item>
                            <Typography className='text-muted m-0 fw-500 lh-25'>In this era of rising passive income, we (Go Passive) have launched a solid generating system that has undergone extensive study and backtesting that has the least possible chances of any error. We are completely committed to investing for the benefit of our clients and to generating profitable returns.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>


            <Grid container className='app-container mt-100'>
                <Grid item xs={12}>
                    <Grid container justifyContent={'center'}>
                        <Grid item>
                            <p className='text-center fw-700 m-0'>Testimonials</p>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent={'center'}>
                        <Box component={'img'}
                            src={Rectangle3}
                            sx={{ maxHeight: 150, minWidth: 280 }}
                        />
                    </Grid>

                    <Grid container className='jc-center'>
                        <Grid item >
                            <Typography className='mw-650 text-center fw-500  fs-24 lh-30'> Our success is based on how consistently we adhere to our key principles: exceptional client service,
                                responsible and sustainable activities throughout the journey, and unwavering support for our
                                employees' development. We are fully devoted to making future a better place.

                                Here’s something our Investors/ clients experienced in our patronage so far, that have reflected their
                                views and thoughts:
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container className='app-container wave-image4 jc-center relative' sx={{ minHeight: { xs: 900 }, alignItems: 'end' }}>
                <Grid item className='bg-color br-30 mw-534 w-100' sx={{ zIndex: -1, minHeight: '550px' }}>
                    <Grid item className='text-center mt-50'>
                        <Box component={'img'}
                            src={ProfileImg}
                            sx={{
                                width: '88px',
                                height: '93px'
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <p className='text-center m-0 fs-24 fw-500'>Sachit Dixit</p>
                        <p className='text-center fs-16 lh-24 fw-500 '>Digital Media Lead, GoPassive</p>
                    </Grid>
                    <Grid item className='pt-10'>
                        <Grid container justifyContent={'center'}>
                            <p className='text-center mw-340 fs-32 fw-500 lh-48'>
                                " The best platform to
                                explore passive income
                                and to invest your hard
                                earned money under
                                expert guidance. "
                            </p>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
            <Grid container className='app-container jc-center ai-center' sx={{ height: '300px' }} >
                <Grid item xs={11} >
                    <Grid container className='jc-center'>
                        <Box component={'img'} className='mr-20'
                            sx={{
                                height: '30px',
                                width: '30px'
                            }}
                            src={Instagram1}
                        />
                        <Box component={'img'} className='mr-20'
                            sx={{
                                height: '30px',
                                width: '30px'
                            }}

                            src={Instagram2}
                        />
                        <Box component={'img'} className='mr-20'
                            sx={{
                                height: '30px',
                                width: '30px'
                            }}
                            src={Instagram3}
                        />
                        <Box component={'img'} className='mr-20'
                            sx={{
                                height: '30px',
                                width: '30px'
                            }}
                            src={Instagram4}
                        />
                    </Grid>
                    <Grid container className='jc-sb mt-30'>
                        <Grid item><Typography className='fs-20 text-muted'>Terms of Use</Typography></Grid>
                        <Grid item><Typography className='fs-20 text-muted'>Privacy Policy</Typography></Grid>
                        <Grid item><Typography className='fs-20 text-muted'>FAQ</Typography></Grid>
                        <Grid item><Typography className='fs-20 text-muted'>Careers</Typography></Grid>
                        <Grid item><Typography className='fs-20 text-muted'>Roadmap</Typography></Grid>
                        <Grid item><Typography className='fs-20 text-muted'>Support</Typography></Grid>
                    </Grid>
                </Grid>
            </Grid>







        </div>
        </>
    )
}
