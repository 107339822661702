import React from 'react'
import { useSelector } from 'react-redux';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { Box } from '@mui/system';

export default function PublicLayout({ children }) {
  
 

    return (
        <>
            <Header />
          
            <main className='fixed-full-view'>
                <Box sx={{ marginTop: { xs: '40px', sm: '62px', md: '67px', lg: '78px' }, marginBottom: { xs: '60px', sm: '10px', md: '10px' }, minHeight: '100vh' }}>
                    {children}
                </Box>
                
            </main>
            
            <Footer />
        </>
    )
}
