import { Grid } from '@mui/material'
import React from 'react'

export default function Login() {
  return (
    <>
      <Grid container>
        <Grid item className='mh-10 h-100 mw-10 w-100' >
          box
        </Grid>
      </Grid>
    </>
  )
}
